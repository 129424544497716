import React, { useState } from "react";
import emailjs from "emailjs-com";

const ContactForm = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_ht0eyyf", // Replace with your EmailJS Service ID
        "template_mzp9l59", // Replace with your EmailJS Template ID
        formData,
        "e7mugKmvFnLfVgplg" // Replace with your EmailJS User ID (or API Key)
      )
      .then(
        (response) => {
          setStatus("Message sent successfully!");
          setFormData({ name: "", email: "", message: "" }); // Reset form
        },
        (error) => {
          console.error("Failed to send message:", error);
          setStatus("Failed to send message. Please try again.");
        }
      );
  };

  return (
    <div id="contact" className="py-12 px-6 bg-gray-100">
      <h2 className="text-3xl font-semibold text-center">Get in Touch</h2>
      <form
        onSubmit={handleSubmit}
        className="max-w-xl mx-auto mt-8 bg-white p-6 shadow-md rounded-md"
      >
        <div className="mb-4">
          <label className="block font-medium text-gray-700" htmlFor="name">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block font-medium text-gray-700" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block font-medium text-gray-700" htmlFor="message">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            rows="5"
            className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
        >
          Send Message
        </button>
        {status && <p className="mt-4 text-center text-sm text-gray-700">{status}</p>}
      </form>
    </div>
  );
};

export default ContactForm;
